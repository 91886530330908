var lang = {};
$(function(){
	switch($("html").attr("lang")) {
		case "en":
		case "en-gb":
			$.extend(lang, {
				contactus_complete: '<p>Thank you! Your message has been sent successfully.</p><button class="btn btn-lg btn-block btn-hero-primary mt-5" data-dismiss="modal">Close</button>',
				fondyApproved: 'Order is approved',
				fondyDeclined: 'Order is declined',
				fondyProcessing: 'Payment could not be processed'
			});
			$.extend($.validator.messages, {
				emailRequired: "Please enter your email",
				passwordRequired: "Please set a password",
				equalTo: "Passwords do not match",
				email: "Please enter a valid address",
				minlength: $.validator.format("Password must be at least {0} characters long"),
				validateidnemail: "Please enter a valid address",
				emailTaken: 'This email is already in use. Would you like to <a href="/signin?notice=complete">sign in</a>?',
				required: "This field is required"
			});
		break;
		case "ru":
		case "ru-ru":
		default:
			$.extend(lang, {
				contactus_complete: '<p>Спасибо! Ваше сообщение отправлено.</p><button class="btn btn-lg btn-block btn-hero-primary mt-5" data-dismiss="modal">Закрыть</button>',
				fondyApproved: 'Счёт оплачен',
				fondyDeclined: 'Заказ отклонен',
				fondyProcessing: 'Платёж не прошёл',
			});
			$.extend($.validator.messages, {
				emailRequired: "Пожалуйста, введите ваш email",
				passwordRequired: "Пожалуйста, задайте пароль",
				equalTo: "Пароли не совпадают",
				email: "Пожалуйста, укажите корректный email",
				minlength: $.validator.format("Длина пароля должна быть не менее {0} символов"),
				validateidnemail: "Пожалуйста, укажите корректный email",
				emailTaken: 'Такой адрес уже зарегистрирован. Хотите <a href="/signin?notice=complete">войти</a>?',
				required: "Это поле обязательно"
			});
		break;
	}
});