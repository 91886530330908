

// Service Worker
if ('serviceWorker' in navigator) {
	if (navigator.serviceWorker.controller) {
		console.log('Active service worker found, no need to register')
	} else {
		// регистрация сработала
		navigator.serviceWorker.register('/sw.js').then(function (reg) {
		}).catch(function (error) {
			// регистрация прошла неудачно
			console.log('Registration failed with ' + error);
		});
 	};
}

let deferredPrompt;
window.addEventListener('beforeinstallprompt', (e) => {
	// Prevent Chrome 67 and earlier from automatically showing the prompt
	e.preventDefault();
	deferredPrompt = e;
	$(".js_prompt").show();
});

function scrollToElement(selector, duration) {
	if (typeof selector == 'object') {
		var $obj = selector;
	} else {
		var $obj = $(selector);
	}
	if ($obj.length == 0) return;
	var shift = 0;
	if ($("header .header").css("position") == 'fixed') shift = $("header .header").height();
	$(document).scrollTo($obj.offset().top - shift - 10, duration);
}

var dragdrop_cancel = false;

function share_fb(og) {
	FB.ui({
		method: 'share_open_graph',
		action_type: 'og.shares',
		display: 'popup',
		action_properties: JSON.stringify({
			object: {
				'og:type': og.type,
				'og:url': og.url,
				'og:title': og.title,
				'og:description': og.description,
				'og:image': og.image
			}
		})
	});

	return false;
}






$(document).ready(function () {
	$(".js_prompt").click(function(){
		if (typeof deferredPrompt != "undefined") {
			deferredPrompt.prompt().catch(function(e){console.log(e);});
		}
		return false;
	});

	//
	// Audio Player
	//
	$('.js_player').each(function() {
		$(this).audioPlayer();
	});
	$(".js_player-simple").each(function(){
		$(this).audioPlayerSimple();
	});

	//
	// Counter
	// Using custom-counter.js
	//
	$(".js_counter").each(function(){
		$(this)
		.attr("data-val", $(this).html())
		.data("data-newval", 0)
		.html("0");

		$(window).on("scroll", {"counter_span": $(this)}, function(e){
			var counter_pos = e.data['counter_span'].offset().top + e.data['counter_span'].height();
			if ( getBodyScrollTop() + window.innerHeight > counter_pos ) {
				e.data['counter_span'].queue(function(){ counter_animate($(this)); });
				$(this).off(e);
			}
		});
	});

	//
	// Score Chart Animation
	//
	$(".js_scoreChart").each(function(){
		let observer = new IntersectionObserver((entries) => {
			if(entries[0].isIntersecting === true) {
				setTimeout(() => {
					$(this).find(".score-chart-progress-bar").each(function(i){
						$(this).append('<span class="score-chart-count"></span>');
						setTimeout(() => {
							$(this).css('width', $(this).attr('data-percent'));
						}, i*100);
					});

					$(this).find(".score-chart-count").each(function () {
						$(this).prop('Counter', 0).animate({
							Counter: $(this).parent('.score-chart-progress-bar').attr('data-percent')
						}, {
							duration: 2000,
							easing: 'swing',
							step: function (now) {
								$(this).text(Math.ceil(now) +'%');
							}
						});
					});
				}, 200);
				observer.unobserve(this);
			}
		}, { threshold: [0.5] });

		observer.observe(this);
	});

	//
	// Former Counter
	//
	// $('.js_counter').each(function () {
	// 	$(this).counterUp();
	// });

	//
	// Confetti
	//
	$('.js_confetti').each(function () {
		switch ($(this).attr('data-type')) {
			case 'type1': // Custom fireworks
				var end = Date.now() + (5 * 1000); // duration milliseconds
				var interval = setInterval(function() {
					if (Date.now() > end) {
						return clearInterval(interval);
					}
					confetti({
						particleCount: 80,
						startVelocity: 15,
						spread: 360,
						ticks: 60,
						origin: {
							x: Math.random(),
							y: Math.random() - 0.2
						},
						colors: ['#039CFD', '#22D431', '#FC3835', '#FFFDE7', '#FFF9C4', '#FFF59D', '#FFF176', '#FFEE58', '#FFEB3B', '#FDD835', '#FBC02D', '#F9A825', '#F57F17', '#FFFF8D', '#FFFF00', '#FFEA00', '#FFD600']
					});
				}, 200);
				break;
			case 'type2': // Default fireworks
				var end = Date.now() + (15 * 1000);
				var interval = setInterval(function() {
					if (Date.now() > end) {
						return clearInterval(interval);
					}
					confetti({
						startVelocity: 30,
						spread: 360,
						ticks: 60,
						origin: {
							x: Math.random(),
							y: Math.random() - 0.2
						}
					});
				}, 200);
				break;
			default:
				confetti();
				break;
		}
	});

	//
	// WOW
	//
	wow = new WOW().init();

	//
	// Statbar position changing
	//
	$('.js_statbar').each(function () {
		$(window).on("resize scroll", function () {
			if ($(document).scrollTop() + window.innerHeight < $('.js_statbar').offset().top + $('.js_statbar').height()) {
				$('.statbar-inner').css('position', 'fixed');
			} else {
				$('.statbar-inner').css('position', 'static');
			}
		});
	});

	//
	// Swidget desc. toggle
	//
	$('.js_swidget').on('click', function() {
		var sd = $(this).hasClass('showdesc');
		$('.js_swidget[data-group='+$(this).attr("data-group")+']').removeClass('showdesc');
		if (!sd) $(this).addClass('showdesc');
	});


	//
	// Owl Carousel
	//
	$(".js_owl").each(function () {
		switch ($(this).attr("data-type")) {
			case "hero":
				$(this).owlCarousel({
					animateOut: 'fadeOut',
        			animateIn: 'fadeIn',
					autoplaySpeed: 2000,
					autoplayTimeout: 2000,
					items: 1,
					autoplay: false,
					dots: true,
					nav: false,
					// navSpeed: 50,
					// singleItem:true,
					// stagePadding: 100,
					// smartSpeed: 50,
					loop: true
				});
				break;
			case "related-topics":
			case "featured-topics":
				$(this).owlCarousel({
					dotsEach: 1,
					// autoHeight: true,
					// dots: false,
					// loop: true,
					// margin: 15,
					responsiveClass: true,
					responsive:{
						0:{
							items: 1,
							stagePadding: 15,
							// center:true,
							// autoWidth:true,
						},
						576:{
							items: 2,
							stagePadding: 15,
							// nav: true,
							// center:true,
							// autoWidth:true,
						},
						768:{
							items: 2,
							stagePadding: 30,
							// nav: true,
							// center:true,
							// autoWidth:true,
						},
						992: {
							items: 3,
							nav: true,
							// autoWidth:true,
							// center:true,
						}
					},
  					// onInitialized: function() { $('.owl-nav').appendTo('.owl-stage-outer'); }, // to center vertically inside .owl-stage-outer
					navElement: 'div',
					navText: ['<svg viewBox="0 0 24 24"><path d="M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z" /></svg>', '<svg viewBox="0 0 24 24"><path d="M4,10V14H13L9.5,17.5L11.92,19.92L19.84,12L11.92,4.08L9.5,6.5L13,10H4Z" /></svg>']
				});
				break;
			//
			// temp. disabled
			//
			// case "screenshots":
			// 	$(this).owlCarousel({
			// 		autoWidth: true,
			// 		center: true,
			// 		loop: true,
			// 	});
			// 	break;
			// case "review":
			// 	$(this).owlCarousel({
			// 		items: 1,
			// 		center: true,
			// 		autoHeight: true,
			// 		nav: true,
			// 		navElement: 'div',
			// 		navText: ['<svg viewBox="0 0 24 24"><path d="M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z" /></svg>', '<svg viewBox="0 0 24 24"><path d="M4,10V14H13L9.5,17.5L11.92,19.92L19.84,12L11.92,4.08L9.5,6.5L13,10H4Z" /></svg>']
			// 	});
			// 	break;
			default:
				$(this).owlCarousel();
				break;
		}
	});

	//
	// Circliful chart
	//
	$('.circliful-chart').circliful({}, function () {
		alert('done !');
	});

	//
	// Peity
	//
	$(".js_pie").each(function () {
		switch ($(this).attr("data-type")) {
			case "statbar":
				$(this).peity("pie", {
					fill: [$(this).attr("data-color"), "white"],
					radius: 6,
				});
				break;
			case "e-card":
				$(this).peity("donut", {
					fill: [$(this).attr("data-color"), "#E6E7EE"],
				});
				break;
			default:
				$(this).peity("pie");
				break;
		}
	});

	//
	// Fixed height for exercise 2
	//
	$(function () {
		$(window).on("resize", function () {
			$(".js_fixheight")
				.css("height", "")
				.css("height", $(".js_fixheight").height());
		}).resize();
	});

	//
	// Fixed width for exercise 1
	//
	$(function () {
		$(window).on("resize", function () {
			$(".js_fixwidth")
				.css("width", "")
				.css("width", $(".js_fixwidth").width());
		}).resize();
	});

	//TEMPORARILY BLOCKED 2018.08.02 $('[data-toggle="tooltip"]').tooltip();
	if ('ontouchstart' in window) { //touch events
		$('.js_substitute').each(function () {
			$(this).on("click", function () {
				var $form = $(this).closest('form');
				$form.find(".av").html("...");
				for (var i in answers[$(this).index()]) {
					$form.find(".av:eq(" + i + ")").html(answers[$(this).index()][i]);
				}
			});
		});
	} else {
		$('.js_substitute').each(function () {
			$(this)
			.click(function(){
				$(this).data("clicked", true).mouseover();
			})
			.hover(function () {
				var $form = $(this).closest('form');
				for (var i in answers[$(this).index()]) {
					$form.find(".av:eq(" + i + ")").html(answers[$(this).index()][i]);
				}
			}, function () {
				if (!$(this).data("clicked")) {
					var $form = $(this).closest('form');
					$form.find(".av").html("...");
				}
			});
		});
	}

	$('.js_autosize').each(function () {
		$(this)
			.on("input", function () {
				$(this).after('<span id="input_probe">' + $(this).val() + '</span>');
				$(this).css("width", $('#input_probe').width() + 4);
				//$('#input_probe').width();
				$('#input_probe').remove();
				//$(this).css("width", "50px");
				$(".js_inputsrequired").trigger("check");
			})
			.on("click", function () {
				if (!($(this).hasClass("focus"))) { $(this)[0].select(); }
			})
			.on("focusin", function () {
				$(this).animate({ opacity: 1 }, 100, function () { $(this).addClass("focus"); });
			})
			.on("focusout", function () {
				$(this).stop().clearQueue().removeClass("focus");
			});
	});

	$(".js_inputsrequired").on("check", function () {

		var submit = true;
		$(this).find("input, select").each(function () {
			if ($(this).val() == "" || $(this).val() == null) submit = false;
			console.log($(this).val(), submit);
		});
		if (submit) {
			$(this).find("input[type=submit], button[type=submit]").removeAttr("disabled").addClass("btn-primary");
		} else {
			$(this).find("input[type=submit], button[type=submit]").attr("disabled", true).removeClass("btn-primary");
		}

	}).each(function () {
		$(this).find("input:first").focus().select();
	});

	$(".exercise .js_select2").select2({
		minimumResultsForSearch: 100
	}).each(function () {
		$(this).find("option").removeAttr("disabled");
	});

	$(".js_select2.required").each(function () {
		$(this).change(function () {
			$(".js_inputsrequired").trigger("check");
		});
	});

	$(".js_langSelect").each(function () {
		$(this).select2({
			minimumResultsForSearch: -1,
			dropdownParent: $(this).parent()
		}).on('change', function (e) {
			document.location = $(this).select2('val');
		});
	});

	$(".js_dragdrop_target").each(function () {
		$(this).sortable({
			items: ".dd-item",
			connectWith: ".js_dragdrop_target[data-target=" + $(this).attr("data-target") + "], .js_dragdrop_base[data-target=" + $(this).attr("data-target") + "]",
			placeholder: "ui-sortable-placeholder",
			//tolerance: "pointer",
			distance: 12,
			appendTo: document.body,
			beforeStop: function (event, ui) {
				if (ui.placeholder.parent().find("div").length > 2 && ui.placeholder.parent().hasClass("js_dragdrop_target")) {
					dragdrop_cancel = true;
				}
			},
			stop: function (event, ui) {
				if (dragdrop_cancel) { $(this).sortable('cancel'); dragdrop_cancel = false; }
			}
		});
	});

	$(".js_dragdrop_base").each(function () {
		$(this).sortable({
			items: ".dd-item",
			connectWith: ".js_dragdrop_target[data-target=" + $(this).attr("data-target") + "], .js_dragdrop_base[data-target=" + $(this).attr("data-target") + "]",
			placeholder: "ui-sortable-placeholder",
			//tolerance: "pointer",
			distance: 12,
			appendTo: document.body,
			//start: function( event, ui ) {},
			//sort: function( event, ui ) {},
			//receive: function(ev, ui) {},
			beforeStop: function (event, ui) {
				if (ui.placeholder.parent().find("div").length > 2 && ui.placeholder.parent().hasClass("js_dragdrop_target")) {
					dragdrop_cancel = true;
				}
				ui.item.trigger('mouseout');
			},
			stop: function (event, ui) {
				if (dragdrop_cancel) { $(this).sortable('cancel'); dragdrop_cancel = false; }

				var $form = $(this).closest("form");
				var checked = true;
				$form.find(".dd-line_base .dd-items > div").each(function () {
					checked = false;
				});

				if (checked) {
					$form.find("button[type=submit], input[type=submit]").removeAttr("disabled").addClass("btn-primary");
				} else {
					$form.find("button[type=submit], input[type=submit]").attr("disabled", "").removeClass("btn-primary");
				}
			}
		}).find(".dd-item").each(function () {
			$(this).hover(function () {
				$(this).addClass("hover");
			}, function () {
				$(this).removeClass("hover");
			});
		});
	});

	$(".js_dragdrop_form").submit(function () {
		var answer = [];
		$(this).find(".js_dragdrop_target").each(function () {
			var targetArray = [];
			$(this).find(".dd-item").each(function () {
				targetArray.push($(this).attr("id"));
			});
			answer.push(targetArray.join(','));
		});

		$(this).find("#answer").val(answer.join('|'));

		return true;
	});

	$(".js_sortable_base .dd-item").each(function () {
		$(this).click(function () {
			$(this).removeClass("hover");
			if ($(this).parent().hasClass("js_sortable_base")) {
				$(".js_sortable_target[data-target=" + $(this).parent().attr("data-target") + "]:eq(0)").append($(this));
			} else {
				$(".js_sortable_base[data-target=" + $(this).parent().attr("data-target") + "]:eq(0)").append($(this));
			}

			var $form = $(this).closest("form");
			var checked = true;
			$form.find(".dd-line_base .dd-items > div").each(function () {
				checked = false;
			});

			if (checked) {
				$form.find("button[type=submit], input[type=submit]").removeAttr("disabled").addClass("btn-primary");
			} else {
				$form.find("button[type=submit], input[type=submit]").attr("disabled", "").removeClass("btn-primary");
			}
		}).hover(function () {
			$(this).addClass("hover");
		}, function () {
			$(this).removeClass("hover");
		});
	});

	$(".js_sortable_form").submit(function () {
		var answer = [];
		$(this).find(".js_sortable_target").each(function () {
			if (typeof answer[$(this).attr("data-target")] == "undefined") answer[$(this).attr("data-target")] = [];
			var targetArray = [];
			$(this).find(".dd-item").each(function () {
				targetArray.push($(this).attr("id"));
			});
			answer[$(this).attr("data-target")].push(targetArray.join(','));
		});

		for (i in answer) {
			//$(this).find("#answer_" + i).val(answer[i].join('|'));
			$(this).find("#answer_" + i).val(answer[i]);
		}

		return true;
	});

	$(".js_filter").each(function () {
		$(this).find(".input-filter").on("input keyup", { filter: $(this) }, function (e) {
			if ($(this).val().length > 2) {
				var searchwords = e.data.filter.find(".input-filter").val().split(" ");
				var checkwords = function (word, text) {
					var regex = new RegExp(word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
					return !(regex.test(text.replace(/&amp;/g, '&')) === false);
				};
				e.data.filter.find(".input-group-text").addClass("input-group-filtered");
				e.data.filter.find(".input-filter").addClass("input-filter-filtered");
				e.data.filter.find(".input-group-append").show();

				e.data.filter.find(".js_filtering .expl-tree__item")
					.hide()
					.each(function () {
						var expl = $(this).find(".expl-tree__item-desc:first");
						var is_contain = true;
						for (var i in searchwords) { if(i=="insert") continue; if (!checkwords(searchwords[i], expl.html())) { is_contain = false; break; } }
						if (is_contain) {
							$(this).show();
							expl.addClass("filtered").removeClass("filtered-disabled");
							$(this).parents("li.expl-tree__item").show();
						} else {
							expl.addClass("filtered-disabled");
							//if (!($(this).hasClass("expl-tree__item_lvl-1"))) $(this).hide();
						}
					});

				if (e.data.filter.find(".js_filtering .expl-tree__item:visible").length > 0) {
					e.data.filter.find(".js_filtering_noresult").hide();
				} else {
					e.data.filter.find(".js_filtering_noresult").show();
				}
				e.data.filter.find(".js_filtering_clear").show();
			} else {
				e.data.filter.find(".input-group-text").removeClass("input-group-filtered");
				e.data.filter.find(".input-filter").removeClass("input-filter-filtered");
				e.data.filter.find(".input-group-append").hide();

				$(".js_filtering .expl-tree__item")
					.show()
					.each(function () {
						var expl = $(this).find(".expl-tree__item-desc:first");
						expl.removeClass("filtered-disabled filtered");
					});

				e.data.filter.find(".js_filtering_clear, .js_filtering_noresult").hide();
			}
		}).submit(function () { return false; });
	});

	$(".js_filtering_clear").click(function () {
		$(this).closest(".js_filter").find(".input-filter").val('').keyup();
		return false;
	});

	$(".js_dashboardNav").each(function () {
		if (document.location.hash) {
			window.scrollTo(0, 0);
			$(".nav-link"+document.location.hash).click();
		}
	});

	$("#expl-panel-show").click(function () {
		$("#on-page-expl").removeClass("expl-panel_hidden");
		$("body").css('overflow', 'hidden');
	});

	$("#expl-panel-hide").click(function () {
		$("#on-page-expl").addClass("expl-panel_hidden");
		$("body").css('overflow', 'auto');
	});

	form_validate();

	$(".js_disableAutoFill").disableAutoFill({
		randomizeInputName: true,
	    html5FormValidate: true
	});

	$(".js_scrollto").click(function(){
		scrollToElement($(this).attr("href"), 1000);
		history.replaceState({}, '', document.location.origin + document.location.pathname + $(this).attr("href"));
		return false;
	});

	if (document.location.hash != "" && document.location.hash != "#current") {
		scrollToElement(document.location.hash, 1000);
	}

	$(".js_contactUsSubject").each(function () {
		$(this).select2({
			minimumResultsForSearch: -1,
			// dropdownParent: $(this).parent(),
			width: '100%'
		}).on('change', function (e) {
			// document.location = $(this).select2('val');
		});
	});

	$(".js_contactus").click(function(){
		var $modal = $(".modal#contactUsModal");
		var $select = $modal.find("select[name=subject]");

		if ($(this).attr("data-value") && $select.length > 0) {
			$select.val($(this).attr("data-value")).trigger('change.select2');
		} else {
			$select.val("1").trigger('change.select2');
		}
		$modal.modal("show");

		return false;
	});

	$(".exercise .js_scrim").on("click", function(){
		$(".exercise .exercise-scrim").addClass("exercise-scrim_visible");
	});

	$(window).scroll();

	//
	// Exercises and tests result page sharing
	// (yandex share block)
	//
	$('.js_ya_share2').each(function(){
		Ya.share2(this, {
			content: {
				url: $(this).attr('data-url'),
				title: $(this).attr('data-title'),
				description: $(this).attr('data-description'),
				image: $(this).attr('data-image')
			},
			hooks: {
				onready: function () {
					let shareObj = $(this._domNode);
					shareObj
					.find('.ya-share2__item_service_facebook .ya-share2__link')
					.removeAttr('href')
					.click(function(){
						share_fb({
							url: shareObj.attr('data-url'),
							type: 'website',
							title: shareObj.attr('data-title'),
							description: shareObj.attr('data-description'),
							image: shareObj.attr('data-image')
						});
						return false
					});
				}
			}
		});
	});

	$(".js_noticePopup").each(function(){
		$(".js_scrim").on("click", () => {
			$(this).css("display", "flex");
			return false;
		});
		$(".js_noticePopup-close").click(function(){
			$(this).closest('.noticePopup').css("display", "none");
			$(".exercise .exercise-scrim").removeClass("exercise-scrim_visible");
		});
	});
});


/*
 * google analitycs initialization event
 */
function on_ga_loaded() {
	$(".js_GAClient").each(function(){
		if (!$(this).data('href')) $(this).data('href', $(this).attr('href'));
		$(this).attr('href', $(this).data('href') + '&client_id=' + ga.getAll()[0].get('clientId'));
	});

	$(".js_toast_show").each(function(){
		$(this)
			.toast({ animation: true })
			.delay(5000)
			.queue(function(){
				$(this).show().toast('show');
				$(this).dequeue();
			});

		$(this).on('hidden.bs.toast', function(){
			var answer = $(this).data("answer") || 0;
			ga.getAll()[0].send('event', 'Toast Poll', 'User Answer', answer);
			$.ajax({
			    type: "POST",
				url: "",
			    data: {
			    	"view": "lin",
			    	"task": "ajax",
			    	"act": "toastPoll",
			    	"value": answer,
			    }
		    });
		});
	});

	$(".js_toast_answer").each(function(){
		$(this).click(function(){
			$(this).closest(".toast").data("answer", $(this).index() + 1);
			$(".js_toast_show").hide().toast('hide');
			$(".js_toast_complete").show().toast('show');
			return false;
		});
	});

	$(".js_toast_complete").toast({
		animation: true,
		autohide: true,
		delay: 3000
	});
}


var check_ga_timer;
function check_ga() {
	if (typeof ga === 'function' && typeof ga.getAll === 'function') {
		on_ga_loaded();
		clearTimeout(check_ga_timer);
	} else {
		check_ga_timer = setTimeout(check_ga,300);
	}
}
check_ga();

$(window).on('load', function()  {

	$('.js_removeonload').remove();
	$('.js_showonload').removeClass('js_showonload').removeClass('d-none');

	//
	// SVG injector
	//
	$('.js_svg-injector').each(function () {
		var $wrapper = $($(this).attr('data-wrapper'));
		SVGInjector($(this), null, function () {
			$wrapper.removeClass('img-preloader-bg');
		});
	});

	//
	// IMG on load
	//
	$('.js_img-onload').each(function () {
		var $wrapper = $($(this).attr('data-wrapper'));
		$(this).attr("src", $(this).attr('data-src')).removeAttr('data-src');
		$wrapper.removeClass('img-preloader-bg');
	});

	//
	// Scroll to current topic
	//
	$(".js_topicFollowing").each(function(){
		if ($(this).parent().find(".unit__topic:first")[0] == this) {
			if ($(this).closest(".js_unitFollowing").parent().find(".unit__wrapper:first")[0] == $(this).closest(".js_unitFollowing")[0]) {
				if (document.location.hash != "#current") {
					scrollToElement("body", 800);
				} else {
					scrollToElement($(this).closest(".js_unitFollowing"), 800);
				}
			} else {
				scrollToElement($(this).closest(".js_unitFollowing"), 800);
			}
		} else {
			scrollToElement($(this), 800);
		}
	});

});
